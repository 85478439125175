import React, {useCallback} from 'react';
import { useClassName } from '../util/classnames';

function Hamburger() {
	const x = 3.25;
	const y1 = -3;
	const y2 = 0;
	const y3 = 3;

	return (
		// -5 -> 5 view box
		<svg className='hamburger' viewBox='-5 -5 10 10'>
			<line
				x1={`-${x}`} x2={x}
				y1={y1} y2={y1}
			/>
			<line
				x1={`-${x}`} x2={x}
				y1={y2} y2={y2}
			/>
			<line
				x1={`-${x}`} x2={x}
				y1={y3} y2={y3}
			/>
		</svg>
	)
}

interface Properties extends Pick<React.HTMLAttributes<HTMLButtonElement>, "className"> {
	targetId: string
	id: string
	toggle:() => void
	checked: boolean
};

export default function Toggler(props: Properties) {
	const {targetId, id, toggle, checked} = props;

	const onClick = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			toggle();
		},
		[toggle]
	);

	const className = useClassName(["toggler", props.className]);

	return (
		<button
			className={className}
			type="button"
			id={id}
			data-toggle="collapse"
			data-target={`#${targetId}`}
			aria-controls={targetId}
			aria-expanded={!checked}
			aria-label="Toggle Navigation"
			onClick={onClick}
		>
			<Hamburger />
		</button>
	)
}