import React, {useMemo} from 'react';
import Card from './Card';

function isIp(str: string) {
	const parts = str.split(/\./g);
	const fourParts = parts.length >= 4;
	if(!fourParts) return false;

	const potentialIpSegments = parts.slice(-4);
	const ipSegments = potentialIpSegments.map(seg => parseInt(seg, 10));
	const segmentsValidNumbers = ipSegments.every(seg => Number.isFinite(seg));

	if(!segmentsValidNumbers) return false;

	const validSegmentNumbers = ipSegments.every(seg => seg < 256 && seg >= 0);

	return validSegmentNumbers;
}

export default function Home() {
	const hostname = useMemo(() => {
		const host = window.location.hostname;
		const parts = window.location.hostname.split(/\./g);
		let displayedParts: string[];

		if(isIp(host)) displayedParts = parts.slice(-4);
		else displayedParts = parts.slice(-2);

		return displayedParts.join(".");
	}, []);

	return (
		<span>
			<Card>
				<h1>Welcome to {hostname}!</h1>
			</Card>
			<Card>
				<h3>
					This is just something simple made in react for the sake of having the name space not be empty.
				</h3>
				<p>
					It was designed to be simplistic and easy on the eyes.
				</p>
			</Card>
		</span>
	);
}
