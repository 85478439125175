import React, { MutableRefObject, RefObject, SetStateAction, useCallback, useMemo, useRef, useState } from "react";
import { CellConnections, CellIndex, CellRef, Color, Connection, X, Y, useGameContext } from "./game";

export const cellMaxSize = 64;

function ConnectionLine(props: {connection?: Connection, to?: boolean}) {
    const {connection, to} = props;
    if(connection === undefined) return null;

    if(connection === Connection.Self) {
        const radius = to ? .5 : 1;
        return (
            <circle x={0} y={0} r={radius} fill="stroke" />
        );
    }
    
    let x1, x2;
    if(connection === Connection.East) {
        x1 = -0.5;
        x2 = 2;
    } else if(connection === Connection.West) {
        x1 = 0.5;
        x2 = -2;
    }
    
    let y1, y2;
    if(connection === Connection.North) {
        y1 = 0.5;
        y2 = -2;
    } else if(connection === Connection.South) {
        y1 = -0.5;
        y2 = 2;
    }

    

    return (
        <line x1={x1} x2={x2} y1={y1} y2={y2} />
    );
}

interface CellProps {
    cellRef: RefObject<CellRef>
    source?: Color
    x: X
    y: Y
    active: boolean
}

export default function Cell(props: CellProps) {
    const {cellRef, source, x, y, active} = props;
    const {cellEntered, cellClicked, getPath, setPath, clearPathTo} = useGameContext();
    const cell = useMemo<CellIndex>(() => ([x, y]), [x, y]);

    const [filled, _setFilled] = useState<CellConnections>(() => ([
        source !== undefined ? Connection.Self : undefined,
        undefined,
    ]));
    const setFilled = useCallback((action: SetStateAction<CellConnections>) => {
        _setFilled(action);
        _setFilled(cur => {
            let ret: CellConnections = [...cur];

            const hasSource = source !== undefined;
            const fromNotSelf = cur[0] !== Connection.Self;
            if(hasSource && fromNotSelf) ret = [Connection.Self, cur[1]];
            
            if(ret.every(val => val === undefined)) {
                (ref.current.color as any) = undefined;
            }

            return ret;
        });
    }, [_setFilled, source]);

    const [
        from,
        to = active ? Connection.Self : undefined,
    ] = filled;

    const ref = useRef<CellRef>({
        enteredFrom: () => {},
        exitedTo: () => {},
        setConnections: setFilled,
        source,
        color: source,
        connections: filled,
        id: cell
    });
    (ref.current.connections as CellConnections) = filled;
    (ref.current.id as CellIndex) = cell;

    ref.current.enteredFrom = useCallback((target, cell) => {
        console.log(ref.current, "entered from", cell);
        const emptyOrMatchesColor = ref.current.color === undefined || ref.current.color === cell.color;
        if(!emptyOrMatchesColor) return;

        setFilled((cur) => {
            const [from, to] = cur;
            
            if(to === target) {
                return [from, undefined];
            }
            if(from === Connection.Self) {
                return [from, target];
            }

            (ref.current.color as any) = cell.color;
            return [target, undefined];
        });
    }, [setFilled]);

    ref.current.exitedTo = useCallback((target, cell) => {
        console.log(ref.current, "exited to", cell);
        if(cell.color !== ref.current.color && cell.color !== undefined) return;

        setFilled((cur) => {
            const [from, to] = cur;
            if(!ref.current.color) return cur;
            const path = getPath(ref.current.color);

            if(!path) {
                return cur;
            }
            const [pathSource, steps] = path;

            if(target === from) {
                (ref.current.color as any) = source;

                window.setTimeout(() => {
                    setPath(ref.current.color!, pathSource, steps.slice(0, -1));
                }, 0);
                
                return [undefined, undefined];
            }
            if(from === Connection.Self && target === to) {
                (ref.current.color as any) = source;

                window.setTimeout(() => {
                    setPath(ref.current.color!, pathSource, steps.slice(0, -1));
                }, 0);

                return [from, undefined];
            }

            
            window.setTimeout(() => {
                setPath(ref.current.color!, pathSource, steps.concat(target));
            }, 0);

            return [from, target];
        })
    }, [source, setFilled, getPath, setPath]);

    (ref.current.source as any) = source;

    (cellRef as MutableRefObject<CellRef>).current = ref.current;

    const onMouseEnter = useCallback(() => {
        cellEntered(cell);
    }, [cellEntered, cell]);

    const onClick = useCallback(() => {
        if(from === undefined) return;

        cellClicked(cell);
        if(active) return;
        if(source !== undefined) {
            setPath(source, cell, []);
        } else if(ref.current.color !== undefined) {
            clearPathTo(ref.current.color, cell);
        }
    }, [cellClicked, cell, from, active, clearPathTo, setPath, source]);

    return (
            <svg
                className={active ? "active" : undefined}
                viewBox="-2 -2 4 4"
                fill={ref.current.color}
                stroke={ref.current.color}
                style={{maxWidth: cellMaxSize, maxHeight: cellMaxSize}}
                onMouseEnter={onMouseEnter}
                onMouseDown={onClick}
            >
                <ConnectionLine connection={from} />
                <ConnectionLine connection={to} to />
            </svg>
    )
}