import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import logo from '../logo.svg';
import Home from './Home';
import AboutMe from './AboutMe';
import NavItem from '../nav/NavItem';
import Toggler from './Hamburger';
import { useClassName } from '../util/classnames';
import Flow from '../flow/Flow';

const routes = [
	{
		path: "/",
		label: "Home",
		component: Home,
	},
	{
		path: "/about-me",
		label: "About Me",
		component: AboutMe,
	},
	// {
	// 	path: "/flow",
	// 	label: "Flow",
	// 	component: Flow,
	// },
]

function App() {
	const [opened, setOpened] = useState(false);
	const toggleNavList = useCallback(() => setOpened(c => !c), []);
	const hideNavList = useCallback(() => setOpened(false), []);

	const location = useLocation();
	useEffect(() => {
		hideNavList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const latestOpened = useRef(false);
	latestOpened.current = opened;
	useEffect(() => {
		function onClick() {
			if(latestOpened.current) {
				hideNavList();
			}
		}

		const events: (keyof WindowEventMap)[] = ["click"]

		const cleanups = events.map(event => {
			window.addEventListener(event, onClick);
			return () => window.removeEventListener(event, onClick);
		});

		return () => cleanups.forEach(cleanup => cleanup());
	}, [hideNavList]);

	const navClassName = useClassName(["navbar", opened || "collapsed"]);

	return (
		<>
			<nav className={navClassName}>
				<div id="branding">
					<Link to="/" className="navbar-brand">
						<img className="App-logo" alt="The app logo" src={logo} height={40} />
					</Link>
					<Toggler
						targetId="myCollapse"
						id="nav-toggler"
						checked={opened}
						toggle={toggleNavList}
					/>
				</div>
				{routes.map(({path, label}) => (
					<NavItem key={path} to={path}>
						{label}
					</NavItem>
				))}
			</nav>
			<div className="container-fluid">
				<Routes>
					{routes.map(route => <Route key={route.path} path={route.path} element={<route.component />} />)}
					<Route path='/flow' element={<Flow />} />
					<Route index element={<Home/>} />
				</Routes>
			</div>
			<br />
			<footer className="footer">
				<div>Website source on <a href="https://gitlab.com/squedgy/website">my Gitlab</a></div>
			</footer>
		</>
	);
}

export default App;
