import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useClassName } from '../util/classnames';

type DivAttrs = React.HTMLAttributes<HTMLDivElement>;
interface CardProps extends DivAttrs {
	bodyClassName?: DivAttrs["className"]
}

export default function Card(props: PropsWithChildren<CardProps>) {
	const {bodyClassName: bodyExtras, className: extras, ...divProps} = props;
	const className = useClassName(["card", extras]);
	const bodyClassName = useClassName(["card-body", bodyExtras])

	return (
		<div {...divProps} className={className}>
			<div className={bodyClassName}>
				{props.children}
			</div>
		</div>
	);
}