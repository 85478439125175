import { useMemo } from "react";

function isNonEmptyString(s: string | undefined) {
    return typeof s === "string" && Boolean(s.trim());
}

export function useClassName(classNames: readonly any[]) {
    return useMemo(
        () => classNames.filter(isNonEmptyString).join(" "),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        classNames
    );
}