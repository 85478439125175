import { Dispatch, SetStateAction, createContext, useContext } from "react";

export type X = number;
export type Y = X;
export type CellIndex = [X, Y];
export type Line = [[X, Y], Connection[]];
export type Color = string;

export enum Connection {
    North,
    South,
    East,
    West,
    Self,
}

export namespace Connection {
    export function asStr(con: Connection): string {
        switch(con) {
            case Connection.North: return "north";
            case Connection.South: return "south";
            case Connection.East: return "east";
            case Connection.West: return "west";
            case Connection.Self: return "self";
    
            default: return "invalid connection";
        }
    }
}

// [from, to]
export type CellConnections = [Connection | undefined, Connection | undefined];

export interface CellRef {
    enteredFrom(to: Connection, cell: CellRef): void
    exitedTo(to: Connection, cell: CellRef): void
    setConnections: Dispatch<SetStateAction<CellConnections>>

    readonly source: Color | undefined
    readonly color: Color | undefined
    readonly connections: CellConnections
    readonly id: CellIndex
}

export interface GameContext {
    readonly mousePosition?: CellIndex
    readonly activeCell?: CellIndex

    getPath(of: Color): Line | undefined
    setPath(of: Color, source: CellIndex, steps: Connection[]): void
    clearPathTo(of: Color, target: CellIndex): void
    getCell(id: CellIndex, neighbor?: Connection): CellRef | undefined
    cellClicked(id: CellIndex): void
    cellEntered(id: CellIndex): void
}

export function getConnection(from: CellIndex, to: CellIndex): undefined | Connection {
    const [x1, y1] = from;
    const [x2, y2] = to;

    const differentX = x1 !== x2;
    const differentY = y1 !== y2;
    const sameCell = !differentX && !differentY;
    const nonLinearChange = differentX && differentY;
    if(sameCell || nonLinearChange) return undefined;

    if(differentX) {
        if(x1 > x2) return Connection.West;
        return Connection.East;
    }

    if(y1 > y2) {
        return Connection.North;
    }

    return Connection.South;
}

export function oppositeConnection(con?: Connection): Connection | undefined {
    switch(con) {
        case Connection.East: return Connection.West;
        case Connection.West: return Connection.East;
        case Connection.South: return Connection.North;
        case Connection.North: return Connection.South;
        default: return undefined;
    }
}

export function getCellOffset(con: Connection): [X, Y] {
    switch(con) {
        case Connection.North: return [0, -1];
        case Connection.South: return [0, 1];
        case Connection.East: return [1, 0];
        case Connection.West: return [-1, 0];
        default: return [0, 0]
    }
}

export function getOffsetCell(id: CellIndex, con: Connection): CellIndex {
    const [x, y] = getCellOffset(con);
    const ret: CellIndex = [id[0] + x, id[1] + y];

    return ret;
}

export function isSameCell(a: CellIndex, b?: CellIndex) {
    return a[0] === b?.[0] && a[1] === b?.[1];
}

export function clearLineAfter(
    line: Line,
    asOf: CellIndex,
    getCell: GameContext["getCell"],
    setPath: GameContext["setPath"],
) {
    const [source, path] = line;

    const start = getCell(source);
    if(!start) {
        console.warn("Invalid line passed to clearLine!");
        return;
    }

    let cell: CellRef | undefined = start;
    const steps = [...path];

    while(cell && !isSameCell(cell.id, asOf) && steps.length > 0) {
        const connection = steps.shift();
        if(connection === undefined) break;

        cell = getCell(getOffsetCell(cell.id, connection));
    }

    const cleanedLength = steps.length;
    if(cell) {
        cell.setConnections(([from]) => [from, undefined]);
    }

    while(cell && steps.length > 0) {
        const nextConnection = steps.shift();
        if(nextConnection === undefined) break;

        cell = getCell(getOffsetCell(cell.id, nextConnection));
        
        if(!cell) break;
        cell.setConnections([undefined, undefined]);
    }

    if(cleanedLength > 0 && start.source) {
        setPath(start.source, source, path.slice(0, -cleanedLength));
    }
}

export function clearLine(
    line: Line,
    getCell: GameContext["getCell"],
    setPath: GameContext["setPath"],
) {
    clearLineAfter(line, line[0], getCell, setPath);
}

const GameContext = createContext<GameContext>({
    getPath() {return [[-1, -1], []];},
    setPath() {},
    clearPathTo() {},
    getCell() {return undefined;},
    cellClicked: () => {},
    cellEntered: () => {},
});

export const GameContextProvider = GameContext.Provider

export function useGameContext(): GameContext {
    return useContext(GameContext);
}