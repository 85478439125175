import React, { RefObject, useMemo, useReducer } from "react";
import { CellIndex, CellRef, Color, GameContext, GameContextProvider, Line, X, Y, clearLine, clearLineAfter, getConnection, getOffsetCell, isSameCell, oppositeConnection } from "./game";
import Cell, { cellMaxSize } from "./Cell";

function cellsCanConnect(
    a: CellRef,
    b: CellRef,
    {getCell}: Pick<GameContext, "getCell">,
): boolean {
    const connection = getConnection(a.id, b.id);

    const noConnection = connection === undefined;
    const disparateColors = a.color !== b.color && b.color !== undefined;
    if(noConnection || disparateColors) {
        return false;
    }

    let clearPath = true;
    let cell = a;
    const targetColor = a.color;

    while(clearPath && !isSameCell(cell.id, b.id)) {
        const nextCell = getCell(getOffsetCell(cell.id, connection));
        const noCell = nextCell === undefined;
        const disparateColor = targetColor !== nextCell?.color && nextCell?.color !== undefined;

        if(noCell || disparateColor) return false;

        cell = nextCell;
    }

    return true;
}

function cellInPath(
    id: CellIndex,
    path: Line | undefined,
): boolean {
    if(!path) return false;

    const [source, steps] = path;

    let curTest = source;
    const mutSteps = [...steps];

    while(!isSameCell(curTest, id) && mutSteps.length > 0) {
        curTest = getOffsetCell(curTest, mutSteps.shift()!);
    }

    return isSameCell(curTest, id);
}

type ConnectionSuccessful = boolean;

function connectCells(
    start: CellRef,
    end: CellRef,
    context: Pick<GameContext, "getCell" | "getPath" | "setPath">,
): ConnectionSuccessful {
    const {getPath, setPath, getCell} = context;

    if(cellsCanConnect(start, end, context)) {
        const path = getPath(start.color!);
        if(!path) return false;
        const [source, existingPath] = path;

        const connection = getConnection(start.id, end.id)!;
        const opposite = oppositeConnection(connection)!;
        
        // assuming end of path
        let cell = getCell(start.id);
        let nextPath = existingPath;

        while(cell && !isSameCell(cell.id, end.id)) {
            const prevCell = cell!;

            nextPath = nextPath.concat(connection);
            cell = getCell(getOffsetCell(start.id, connection));

            if(cell) {
                prevCell.exitedTo(connection, cell!); 
                cell.enteredFrom(opposite, prevCell!);
            }
        }

        nextPath = nextPath.concat(connection);
        console.log(start.color, source, nextPath);
        setPath(start.color!, source, nextPath);

        return true;
    }
    
    return false;
}

export type CellSpec = Color | undefined;
export type BoardSpec = CellSpec[][];

interface BoardProps {
    spec: BoardSpec
}

export default function Board(props: BoardProps) {
    const {spec} = props;

    const [, forceUpdate] = useReducer(a => a ^ 1, 0);

    type Cells = Record<Y, Record<X, RefObject<CellRef>>>;
    const [context, cells] = useMemo<[GameContext, Cells]>(
        () => {
            const paths: Record<Color, Line> = {};

            const getPath: GameContext["getPath"] = color => paths[color];

            const setPath: GameContext["setPath"] = (color, source, steps) => {
                const sourceCell = getCell(source);
                if(sourceCell?.source === undefined) throw new Error(`The source of a path must be a source cell ${JSON.stringify(source)}.\n${JSON.stringify(sourceCell)}`);

                const p = getPath(color);

                if(p) {
                    const [cell, path] = p;

                    if(!isSameCell(cell, source)) {
                        clearLine([cell, path], getCell, setPath);
                    } else {
                        const similarUntil = path.findIndex((con, idx) => steps[idx] !== con);
    
                        if(similarUntil !== -1) {
                            let cell = source;
                            for(let i = 0 ; i < similarUntil ; i++) {
                                cell = getOffsetCell(cell, path[i]);
                            }
                        }
                    }
                }

                paths[color] = [source, steps];
            }

            const clearPathTo: GameContext["clearPathTo"] = (color, updateAt) => {
                const line = getPath(color);
                if(!line) return;

                clearLineAfter(line, updateAt, getCell, setPath);
            }

            const cells: Cells = {};
            for(let y = 0; y < spec.length; y++) {
                cells[y] = {};

                for(let x = 0; x < spec[y].length; x++) {
                    cells[y][x] = {} as RefObject<CellRef>;
                }
            }

            function getCell([x, y]: CellIndex) {
                return cells[y]?.[x]?.current ?? undefined;
            }

            const cellClicked: GameContext["cellClicked"] = id => {
                const cell = getCell(id);
                if(!cell) return;

                if(isSameCell(id, ctx.activeCell)) {
                    ctx.activeCell = undefined;
                } else if(cell.color !== undefined) {
                    ctx.activeCell = id;
                }

                forceUpdate();
            };

            const cellEntered: GameContext["cellEntered"] = id => {
                if(!ctx.activeCell) return;
                
                const activeCell = getCell(ctx.activeCell);
                const targetCell = getCell(id);

                console.log({activeCell, targetCell});
                if(!activeCell || !targetCell) return;

                if(connectCells(activeCell, targetCell, {getPath, setPath, getCell})) {
                    console.log("connected");
                    ctx.activeCell = id;
                } else if(cellInPath(id, getPath(activeCell.color!))) {
                    console.log("in path, truncating");
                    clearLineAfter(getPath(activeCell.color!)!, id, getCell, setPath);
                    ctx.activeCell = id;
                } else {
                    console.log("no connection made");
                }
                
                forceUpdate();
            };


            const ctx: {-readonly [K in keyof GameContext]: GameContext[K]} = {
                getPath, setPath, clearPathTo, cellClicked, cellEntered, getCell,
            };

            return [ctx, cells];
        },
        [spec]
    );

    return (
        <GameContextProvider value={context}>
            <style>{`
#flow .board {max-width: ${cellMaxSize * spec[0].length}px; max-height: ${cellMaxSize * spec.length}px;}
#flow .board > .row {max-width: ${cellMaxSize * spec[0].length}px; max-height: ${cellMaxSize}px;}

            `.trim()}</style>
            <div className="board">
                {
                    spec.map((row, y) => (
                        <div key={`y${y}-${row.length}`} className="row">
                            {
                                row.map((color, x) => (
                                    <Cell
                                        key={`x${x}-${color}`}
                                        x={x}
                                        y={y}
                                        source={color}
                                        cellRef={cells[y][x]}
                                        active={isSameCell([x, y], context.activeCell)}
                                    />
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </GameContextProvider>
    );
}