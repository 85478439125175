import './index.css';
import React from 'react';
import {createRoot} from "react-dom/client";
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
import { HashRouter } from 'react-router-dom';

const thing = createRoot(document.getElementById('root') as HTMLElement);
thing.render(
	<HashRouter>
  		<App />
	</HashRouter>
);

registerServiceWorker();
