import * as React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { useClassName } from '../util/classnames';

interface Props extends Pick<React.HTMLAttributes<any>, "className"> {
	to: string
}

export default function NavItem(props: React.PropsWithChildren<Props>) {
	const { to, } = props;
	const match = useMatch(to);

	const classname = useClassName(["nav-item", Boolean(match) ? "active" : undefined, props.className]);

	return (
		<span className={classname}>
			<Link className="nav-link" to={to}>
				{props.children}
			</Link>
		</span>
	);
}
