import * as React from 'react';
import Card from './Card';

export default function AboutMe() {
	return (
		<span>
			<Card className="text-center">
				<h1>David Faris</h1>
				<p>
					I love programming, and when something really piques my interest I love dedicating some time to
					learning about it.&nbsp;
					<a href="https://gitlab.com/squedgy/square-multiply">Square multiply</a> was among the more
					recent additions.
					It's my first real exposure to a personal c++ project.
					A lot of my projects on display on my github and gitlab are fairly old, and neglected.
					Primarily due to being drained once getting accustomed to working at my current position.
				</p>
				<hr />
				<p>
					There's a number of them that I want to update/remake, if when I find the time.
				</p>
			</Card>
		</span>
	);
}