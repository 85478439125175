import React from "react";
import Card from "../components/Card";
import Board, { BoardSpec } from "./Board";

const boardSpec: BoardSpec = [
    ["red", undefined, "green", undefined, "purple"],
    [undefined, undefined, "blue", undefined, "orange"],
    [undefined, undefined, undefined, undefined, undefined],
    [undefined, "green", undefined, "purple", undefined],
    [undefined, "red", "blue", "orange", undefined]
];

export default function Flow() {
    return (
        <Card id="flow">
            <Board spec={boardSpec}/>
        </Card>
    );
}